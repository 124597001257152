import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios"
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'
import moment from 'moment'
import DualListBox from "dual-listbox-vue"
import Facturesmodal from "../Facturesreglement/Facturesmodal"


Vue.component('downloadExcel', JsonExcel)
Vue.component('v-select', vSelect)
Vue.component('factures-modal', Facturesmodal)



export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        DualListBox
    },
    data: function() {
        return {
            pageTitle: "Liste des factures",

            vuetable: {
                link: this.BASE_URL + "/clientreglements/fetchfacture",
                moreParams: {
                    startDate: moment().format('DD/MM/YYYY'),
                    endDate: moment().format('DD/MM/YYYY')
                },
                fields: [
                    // {
                    //     name: VuetableFieldCheckbox,
                    //     titleClass: 'text-center',
                    //     dataClass: 'text-center',
                    //     selectable
                    // },
                    {
                        name: 'date_facture_formatted',
                        title: 'Date',
                        sortField: 'date_facture',
                        dataClass: 'text-center',
                    },
                    {
                        name: 'nom_magasin',
                        title: this.$t('historique_reglement.magasin'),
                        dataClass: 'text-left',
                        sortField: 'nom_magasin',
                    },
                    {
                        name: 'num_facture',
                        title: this.$t('historique_reglement.facture'),
                        dataClass: 'text-center',
                        sortField: 'num_facture',
                        titleClass: 'text-center',
                    },
                    {
                        name: 'nom_client',
                        title: this.$t('historique_reglement.client'),
                        dataClass: 'text-left',
                        sortField: 'nom_client',
                    },
                    {
                        name: 'total_a_payer',
                        title: this.$t('historique_reglement.total_payer'),
                        dataClass: 'text-right text-bold',
                        sortField: 'total_a_payer',
                        width: "150px",
                    },
                    {
                        name: 'total_deja_paye',
                        title: "Total déjà payé",
                        dataClass: 'text-right text-bold text-success',
                        sortField: 'total_deja_paye',
                        width: "150px",
                    },
                    {
                        name: 'total_restant',
                        title: this.$t('historique_reglement.reste_a_payer'),
                        dataClass: 'text-right text-bold text-danger',
                        sortField: 'total_restant',
                        width: "150px",
                    },
                    {
                        name: 'statut_paiement',
                        title: this.$t('historique_reglement.statut'),
                        dataClass: 'text-left',
                        sortField: 'statut_paiement',
                        width: "100px",
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: this.$t('historique_reglement.date_enregistrement'),
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        sortField: 'date_heure_enregistrement',
                        width: "150px",
                    },

                    {
                        name: 'actions',
                        title: 'Voir',
                        width: "80px",
                        dataClass: "text-center",
                        titleClass: 'text-center',
                    }

                ],
                sortOrder: [
                    { field: 'date_facture', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered', //table-striped 
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars fa-sm',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_facture_formatted',
                    'Numero': 'num_facture',
                    'Client': 'nom_client',
                    'Total Montant HT': 'total_ht',
                    'Total Montant TVA': 'total_tva',
                    'Total Montant TTC': 'total_ttc',
                    'Total Remise': 'total_remise',
                    'Total Montant A payer': 'total_a_payer',
                    'Commentaire': 'commentaire',
                    'Date Heure Enregistrement': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            datepicker: {
                format: 'DD/MM/YYYY',
                useCurrent: false,
            },
            motCle: "",
            crudform: {
                id: "",
                date_reglement_formatted: moment().format('DD/MM/YYYY'),
                date_echeance: moment().format('DD/MM/YYYY'),
                factures_id: "",
                montant: "",
                reference: "",
                mode_paiement: "ESPECE",
                piece_jointe: "",
                remarques: "",
                reste_a_payer: "",
            },

            criterearticle: {
                famille: "",
                search: ""
            },
            client: {},
            facture: {},
            selectedRow: {
                data: {},
                index: 0
            },
            numero_facture: "",
            firstload: true,
            has_echeance: false,
            somme_compte_client: {
                montant_factureTotal: '',
                montant_payerTotal: '',
                reste_payerTotal: '',
            },
            paiementType: 'auto',
            montant_total_reste_a_payer: "",
            current_date: moment().format('YYYY-MM-DD'),
            filtreDate: {
                dateDebut: moment().format('DD/MM/YYYY'),
                dateFin: moment().format('DD/MM/YYYY')
            },


        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');

            if (this.firstload && this.$refs.vuetable.$data.tableData.length > 0) {
                this.selectRow(this.$refs.vuetable.$data.tableData[0], 0);
            }
            this.firstload = false;
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.criteriacol = this.criteriacol;
            this.vuetable.moreParams.startDate = this.filtreDate.dateDebut;
            this.vuetable.moreParams.endDate = this.filtreDate.dateFin;
            if (this.$route.name == 'Facturesreglementparclient') {
                this.vuetable.moreParams.clients_id = this.$route.params.id;
            }
            if (this.$route.name == 'Facturesreglementparfacture') {
                this.vuetable.moreParams.id = this.$route.params.id;
            }

            Vue.nextTick(() => this.$refs.vuetable.refresh());

            var that = this;

            axios.post(that.BASE_URL + "/clientreglements/fetchmontanttotal", this.vuetable.moreParams).then(function(response) {
                that.somme_compte_client = response.data[0];
                console.log(that.somme_compte_client);
            });

        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.vuetable.moreParams.filter = this.motCle;
            if (this.$route.name == 'Facturesreglementparclient') {
                this.vuetable.moreParams.clients_id = this.$route.params.id;
            }
            if (this.$route.name == 'Facturesreglementparfacture') {
                this.vuetable.moreParams.id = this.$route.params.id;
            }
            //this.vuetable.moreParams.clients_id = 4;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("myCustomModal");
        },
        closeModal() {
            this.$bvModal.hide("myCustomModal");
        },

        getClient: function(id_client) {
            if (id_client == '' || id_client == null) {
                this.client = {};
            } else {
                axios.get(this.BASE_URL + "/clients/get/" + id_client).then(response => {
                    this.client = response.data[0];
                    this.pageTitle = "Liste des factures du client : " + this.client.nom_client;
                    this.$emit('change-page', this.pageTitle);
                });
            }

        },
        getFacture: function(id_facture) {
            console.log("FAC: " + id_facture);
            if (id_facture == '' || id_facture == null) {
                this.facture = {};
            } else {
                axios.get(this.BASE_URL + "/clientreglements/get/" + id_facture).then(response => {
                    this.facture = response.data[0];
                    this.pageTitle = "Historique des règlements de : " + this.facture.num_facture;
                    this.$emit('change-page', this.pageTitle);
                    console.log(this.pageTitle);
                });
            }

        },
        updateMontant: function() {
            // this.crudform.reste_a_payer = Number(this.selectedRow.data.total_restant) - Number(this.crudform.montant);
            this.crudform.reste_a_payer = Number(this.montant_total_reste_a_payer) - Number(this.crudform.montant);
        },
        initForm: function() {
            this.crudform.date_reglement_formatted = moment().format('DD/MM/YYYY');

            this.crudform.mode_paiement = "ESPECE";
            this.crudform.reference = "";
            this.crudform.remarques = "";
            this.crudform.montant = 0;

        },
        selectRow: function(rowData, index) {

            this.selectedRow.index = index;
            this.selectedRow.data = rowData;

            this.crudform.factures_id = rowData.id;

            if (this.paiementType == 'auto') {
                this.crudform.reste_a_payer = this.montant_total_reste_a_payer;
                this.montant_total_reste_a_payer = Number(this.somme_compte_client.reste_payerTotal);
            } else {
                this.crudform.reste_a_payer = rowData.total_restant;
                this.montant_total_reste_a_payer = Number(rowData.total_restant);
            }

            console.log(this.crudform.reste_a_payer);
            console.log("-----------------------");

            this.initForm();
            // this.fetchreglements(rowData.id);

        },
        onRowClicked: function(e) {
            this.selectRow(e.data, e.index);

        },
        onRowClass: function(dataItem) {

            // if(this.paiementType == 'manuel') {
            if (dataItem.id == this.selectedRow.data.id)
                return 'selected-row';

            return '';
        }

    },
    computed: {
        articlesList: function() {
            var that = this;
            return this.listdata.articles.filter(function(article) {
                if (article.ref_article.toLowerCase().includes(that.criterearticle.search.toLowerCase()) || article.designation.toLowerCase().includes(that.criterearticle.search.toLowerCase())) {
                    return true;
                }
                return false;
            });
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        this.motCle = "";
        this.criteriacol = "";

        // this.resetFilter();

        if (this.$route.name == 'Facturesreglementparclient') {
            this.getClient(this.$route.params.id);
            this.setFilter();
        }


    },
    watch: {
        'selectedRow.data': function() {
            // console.log(this.selectedRow.data)
            if (this.selectedRow.data != null && this.selectedRow.data != undefined && this.selectedRow.data != {}) {
                this.numero_facture = this.selectedRow.data.num_facture;
            } else {
                this.numero_facture = "";
            }

        },
        paiementType: function() {
            this.initForm();
            if (this.paiementType == 'auto') {
                this.crudform.reste_a_payer = this.montant_total_reste_a_payer;
                this.montant_total_reste_a_payer = Number(this.somme_compte_client.reste_payerTotal);
            } else {
                this.crudform.reste_a_payer = this.selectedRow.data.total_restant;
                this.montant_total_reste_a_payer = this.selectedRow.data.total_restant;
                // console.log(this.crudform);
            }
        }

    },
    mounted: function() {

    }
}